<template>
    <div id="payment-details-target">
        <div class="application-section-title">{{ $t('APPLICATION.PAYMENT.SECTION_TITLE') }}</div>
        <b-card class="st-section mt-6 payment-section">
            <div class="st-section-body">
                <div class="payment-section-title">{{ $t('APPLICATION.PAYMENT.TITLE') }}</div>
                <div class="payment-help">
                    <p class="payment-help-text">{{ helpText }}</p>
                </div>
                <div class="switch-button">
                    <button
                        type="button"
                        class="switch-button-item"
                        :disabled="isPaid"
                        @click.stop="selectPaymentType(PAYMENT_TYPES.CARD)"
                        :class="[selectedPaymentType === PAYMENT_TYPES.CARD ? 'active' : '']"
                    >
                    {{ $t('APPLICATION.PAYMENT.SELECT.ONLINE') }}
                    </button>
                    <button
                        type="button"
                        class="switch-button-item"
                        :disabled="isPaid"
                        @click.stop="selectPaymentType(PAYMENT_TYPES.OP)"
                        :class="[selectedPaymentType === PAYMENT_TYPES.OP ? 'active' : '']"
                    >
                    {{ $t('APPLICATION.PAYMENT.SELECT.OFFLINE') }}
                    </button>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { DO_SET_PAYMENT_DETAILS } from '@/modules/applications/store/applications-form-store';
const { mapGetters, mapActions } = createNamespacedHelpers('applications/form');

export const PAYMENT_TYPES = {
    OP: 'OP',
    CARD: 'CARD' // Payment order
};

export default {
  name: "ApplicationPayment",
  props: {
      paymentDetails: {
          type: Object,
          required: false
      },
      viewMode: {
          type: Boolean,
          required: false,
      },
  },
  data() {
    return {
        PAYMENT_TYPES,
        selectedPaymentType: PAYMENT_TYPES.CARD,
        isPaid: false,
    };
  },
  computed: {
    ...mapGetters(['record']),
    helpText() {
            return this.selectedPaymentType === 'CARD'
            ? this.$t('APPLICATION.PAYMENT.HELP_TEXT_CARD')
            : this.$t('APPLICATION.PAYMENT.HELP_TEXT_OP');
        },
  },
  methods: {
      ...mapActions([DO_SET_PAYMENT_DETAILS]),
      selectPaymentType(paymentType) {
        this.selectedPaymentType = paymentType;
        this.$emit('updatePaymentType', paymentType);
        this[DO_SET_PAYMENT_DETAILS]({ payment_type: paymentType });
      }
  },
  created() {
      if (this.paymentDetails) {
          this.selectedPaymentType = this.paymentDetails.payment_type;
      }
      if (this.record) {
          this.isPaid = this.record.is_paid;
      }
  },
};
</script>
